@import "./assets/scss/fonts.scss";
@import "./assets/scss/abstracts.scss";
@import "./assets/scss/main.scss";

[id="app"] {
  min-height: 100vh;
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: $mid-grey;
  color: $text-color;
  & > .container{
    height: 100vh;
    padding: 25px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    & > .content{
      flex-grow: 1;
    }
  }
}