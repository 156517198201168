@import "../../assets/scss/abstracts.scss";

.player-picture{
    &.player-1{
      background: $color-player-1;
    }
    &.player-2{
      background: $color-player-2;
    }
    background: $light-grey;
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    $skin: #e8cda8;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    &::before{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left:0;
      top: 0;
      transform: translate(35%,35%) rotate(45deg);
      background: rgba(255,255,255,.2);
    }
    .content{
      display: flex;
      justify-content: center;
      align-items: center;
      background: $white;
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
    .head {
      position: relative;
      width: 60%;
      height: 60%;
      color: white;
      background: $skin;
      border-radius: 40% 40% 50% 50%;
      div {
        z-index: 15;
        position: absolute;
        &::after,
        &::before {
          position: absolute;
          display: block;
          z-index: 15;
        }
      }

      [class*="ear-"] {
        top: 35%;
        height: 20%;
        width: 12.5%;
        background: $skin;
        overflow: hidden;
        &::after,
        &::before {
          content: "";
          border-radius: 40% 65% 80% 0;
          width: 100%;
          height: 100%;
        }
        &::before {
          top: 25%;
          background: darken($skin, 10%);
          border-radius: inherit;
        }
        &::after {
          top: 32.5%;
          background: inherit;
          border-radius: inherit;
        }
      }
      .ear-left {
        right: -12%;
        border-radius: 40% 65% 80% 0;
        &::after {
          left: -5%;
        }
      }
      .ear-right {
        left: -12%;
        border-radius: 65% 40% 0 80%;
        &::after {
          right: -5%;
        }
      }
    }
    
    .nose {
      height: 25%;
      width: 10%;
      top: 45%;
      left: 50%;
      border-radius: 20% 100% 20% 5%;
      background: darken($skin, 5%);
      transform: translatex(-50%);
      border-bottom: 5px solid darken($skin, 10%);
    }
    [class*="eye"] {
      height: 12.5%;
      width: 12.5%;
      top: 42.5%;
      background: white;
      border-radius: 50%;
      &::before {
        content: "";
        background: black;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 35%;
        width: 35%;
        border-radius: 50%;
      }
      &::after {
        content: "";
        background: darken($skin, 10%);
        height: 50%;
        width: 100%;
        border-radius: 100% 100% 0 0;
      }
    }
    .eye-left {
      left: 20%;
    }
    .eye-right {
      right: 20%;
    }
    .mouth {
      height: 0.5%;
      width: 40%;
      top: 80%;
      left: 50%;
      transform: translatex(-50%);
      background: darken($skin, 10%);
      &::before {
        content: "";
        background: darken($skin, 5%);
        height: 300%;
        width: 60%;
        border-radius: 100% 100% 0 0;
        bottom: 100%;
        left: 50%;
        transform: translatex(-50%);
      }
      &::after {
        content: "";
        background: darken($skin, 5%);
        height: 300%;
        width: 75%;
        border-radius: 0 0 100% 100%;
        top: 100%;
        left: 50%;
        transform: translatex(-50%);
      }
    }
    
}
