@import "../../assets/scss/abstracts.scss";

.board{
    width: 100%;
    padding: 0 25px;
    position: relative;
    overflow: hidden;
    .board-table{
        position: relative;
        padding: 25px;
        border: 5px solid darken($main-color-beta, 10%);
        background: $main-color-beta;
        &::after{
            content: "";
            position: absolute;
            right: 5px;
            bottom: 5px;
            width: 0; 
            height: 0; 
            border-left: 30px solid transparent;
            border-right: 30px solid transparent;
            border-top: 30px solid darken($main-color-beta, 10%);
            transform: translate(25px, 10px) rotate(-45deg);
        }
    }
}
