@import "../../assets/scss/abstracts.scss";

$color: yellow;
.trophy {
  margin: auto;
  background: #dedede;
  border-radius: 50%;
  border: 10px solid black;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.player-1 {
    border-color: $color-player-1;
  }
  &.player-2 {
    border-color: $color-player-2;
  }
  .picture {
    z-index: 15;
    position: relative;
    width: 30%;
    height: 30%;
    z-index: 15;
    border-radius: 50%;
    transform: translate(0, -25%);
    div {
      z-index: 0;
      position: absolute;
      &::after,
      &::before {
        position: absolute;
        display: block;
        z-index: 15;
      }
    }
    div:nth-child(1) {
      width: 100%;
      height: 100%;
      background: $color;
      background: linear-gradient($color, darken($color, 10%));
      border-radius: 0 0 60% 60% / 0 0 100% 100%;
      z-index: 16;
    }
    div:nth-child(2) {
      background: $color;
      width: 100%;
      height: 25%;
      bottom: 100%;
      left: 0;
      border-radius: 5% 5% 0 0;
      &::after,
      &::before {
        z-index: 10;
        content: "";
        background: darken($color, 10%);
        background: transparent;
        width: 40%;
        height: 200%;
        top: 100%;
        border: 5px solid darken($color, 10%);
        border-radius: 35%;
      }
      &::before {
        right: -22%;
        transform: rotate(33deg);
      }
      &::after {
        left: -22%;
        transform: rotate(-33deg);
      }
    }
    div:nth-child(3) {
      z-index: 16;
      height: 10%;
      width: 50%;
      background: darken($color, 10%);
      left: 25%;
      bottom: -5%;
      border-radius: 50% / 35%;
    }
    div:nth-child(4) {
      height: 55%;
      width: 20%;
      background: darken($color, 2.5%);
      left: 40%;
      bottom: -40%;
      border-radius: 70% 70% 0 0 / 100% 100% 0 0;
    }
    div:nth-child(5) {
      height: 10%;
      width: 50%;
      background: darken($color, 10%);
      left: 25%;
      bottom: -45%;
      border-radius: 50% / 35%;
    }
    div:nth-child(6) {
      height: 10%;
      width: 60%;
      background: darken($color, 15%);
      left: 20%;
      bottom: -50%;
      border-radius: 50% / 35%;
    }
    div:nth-child(7) {
      z-index: 16;
      height: 25%;
      width: 80%;
      background: brown;
      left: 10%;
      bottom: -72%;
      border-radius: 1%;
    }
    div:nth-child(8) {
      z-index: 16;
      height: 10%;
      width: 90%;
      background: darken(brown, 15%);
      left: 5%;
      bottom: -80%;
      border-radius: 1%;
    }
    div:nth-child(9) {
      z-index: 16;
      height: 10%;
      width: 40%;
      background: $color;
      left: 30%;
      bottom: -65%;
      border-radius: 1%;
      border: 2px solid darken($color, 25%);
    }
  }
}
