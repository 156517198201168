@import "../../assets/scss/abstracts.scss";

.player-card{
    position: relative;
    background: $main-color-beta;
    border: 4px solid $main-color-beta;
    text-align: center;
    padding: 20px;
    margin-top: 50px;
    transition: border .25s ease;
    * {
        position: relative;
        z-index: 2;
    }
    h2{
        margin: 10px 0;
        color: currentcolor;
        font-size: 2.5em;
        font-weight: 900;
        text-transform: uppercase;
    }
    &::after{
        content: "";
        position: absolute;
        top: 15px;
        width: 0; 
        height: 0; 
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid $main-color-beta;
        z-index: 2;
        transition: border .25s ease;
    }
    &::before{
        content: "";
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background-image: 
        linear-gradient(216deg, rgba(77, 77, 77,0.05) 0%, rgba(77, 77, 77,0.05) 25%,rgba(42, 42, 42,0.05) 25%, rgba(42, 42, 42,0.05) 38%,rgba(223, 223, 223,0.05) 38%, rgba(223, 223, 223,0.05) 75%,rgba(36, 36, 36,0.05) 75%, rgba(36, 36, 36,0.05) 100%),
        linear-gradient(44deg, rgba(128, 128, 128,0.05) 0%, rgba(128, 128, 128,0.05) 34%,rgba(212, 212, 212,0.05) 34%, rgba(212, 212, 212,0.05) 57%,rgba(25, 25, 25,0.05) 57%, rgba(25, 25, 25,0.05) 89%,rgba(135, 135, 135,0.05) 89%, rgba(135, 135, 135,0.05) 100%),
        linear-gradient(241deg, rgba(55, 55, 55,0.05) 0%, rgba(55, 55, 55,0.05) 14%,rgba(209, 209, 209,0.05) 14%, rgba(209, 209, 209,0.05) 60%,rgba(245, 245, 245,0.05) 60%, rgba(245, 245, 245,0.05) 69%,rgba(164, 164, 164,0.05) 69%, rgba(164, 164, 164,0.05) 100%),
        linear-gradient(249deg, rgba(248, 248, 248,0.05) 0%, rgba(248, 248, 248,0.05) 32%,rgba(148, 148, 148,0.05) 32%, rgba(148, 148, 148,0.05) 35%,rgba(202, 202, 202,0.05) 35%, rgba(202, 202, 202,0.05) 51%,rgba(181, 181, 181,0.05) 51%, rgba(181, 181, 181,0.05) 100%),
        linear-gradient(309deg, rgba(248, 248, 248,0.05) 0%, rgba(248, 248, 248,0.05) 32%,rgba(148, 148, 148,0.05) 32%, rgba(148, 148, 148,0.05) 35%,rgba(202, 202, 202,0.05) 35%, rgba(202, 202, 202,0.05) 51%,rgba(181, 181, 181,0.05) 51%, rgba(181, 181, 181,0.05) 100%),
        linear-gradient(144deg, rgba(128, 128, 128,0.05) 0%, rgba(128, 128, 128,0.05) 34%,rgba(212, 212, 212,0.05) 34%, rgba(212, 212, 212,0.05) 57%,rgba(25, 25, 25,0.05) 57%, rgba(25, 25, 25,0.05) 89%,rgba(135, 135, 135,0.05) 89%, rgba(135, 135, 135,0.05) 100%),
        linear-gradient(92deg, hsl(214,0%,11%),hsl(214,0%,11%));
        mix-blend-mode: difference;
        opacity: .75;
        z-index: 1;
    }
    &.player-1{
        color: $color-player-1;
        &.active{
            border-color: $color-player-1;
            &::after{
                border-bottom-color: $color-player-1;
            }
        }
        &::after{
            right: 15px;
            transform: translate(20px, -10px) rotate(45deg);
        }
    }
    &.player-2{
        color: $color-player-2;
        &.active{
            border-color: $color-player-2;
            &::after{
                border-bottom-color: $color-player-2;
            }
        }
        &::after{
            left: 15px;
            transform: translate(-20px, -10px) rotate(-45deg);
        }
    }

}
