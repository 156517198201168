@import "../../../assets/scss/abstracts.scss";

.round{
    width: 14.28%;
    margin: 5px;
    aspect-ratio: 1 / 1;
    background: $white;
    border: 1px solid darken(#c4c3be,.5);
    border-radius: 50%;
    overflow: hidden;
    box-shadow: inset 2px 2px 5px 2px rgba($black,.5);
    [class*=status] {
        width: 100%;
        height: 100%;
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            top: 25%;
            left: 25%;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgba($white,.05);
            z-index: 10;
        }
        span{
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 5% solid rgba($white,.075);
            z-index: 5;
            &::after{
                content: "";
                display: block;
                position: absolute;
                left: 10%;
                top: 10%;
                width: 80%;
                height: 80%;
                border-radius: 50%;
                border: 1px solid rgba($white,.15);
            }
            &::before{
                content: "";
                display: block;
                position: absolute;
                left: 5%;
                top: 5%;
                width: 90%;
                height: 90%;
                border-radius: 50%;
                background: rgba($white,.05);
                border: 1px solid rgba($white,.15);
            }
        }
        &::after{
            content: "✔";
            font-size: 3em;
            color: rgba($white,.65);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            display: none;
        }
        &.status-1{
            background: $color-player-1;
        }
        &.status-2{
            background: $color-player-2;
        }
        &.status-3{
            background: $color-player-1;
            &::after{
                display: block;
            }
        }
        &.status-4{
            background: $color-player-2;
            &::after{
                display: block;
            }
        }
    }
}
