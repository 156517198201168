@import "../../assets/scss/abstracts.scss";

.home{
  position: relative;
  width: auto;
  height: 50%;
  display: flex;
  margin: 0 auto;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1{
    font-weight: 900;
    text-transform: uppercase;
    font-size: 5em;
    color: $color-player-1;
    margin: 0 0 100px 0;
    &::before{
      content: "";
      width: 20px;
      height: 20px;
      background: $color-player-2;
      border-radius: 50%;
      position: absolute;
      left: 20px;
      top: calc(50% - 20px);
    }
    &::after{
      content: "";
      width: 20px;
      height: 20px;
      background: $color-player-2;
      border-radius: 50%;
      position: absolute;
      left: 20px;
      top: calc(50% - 20px);
      transform: translateX(30px);
    }
    span{
      color: $color-player-2;
      font-size: 1.5em;
      &::before{
        content: "";
        width: 20px;
        height: 20px;
        background: $color-player-2;
        border-radius: 50%;
        position: absolute;
        left: 20px;
        top: calc(50% - 20px);
        transform: translateX(60px);
      }
      &::after{
        content: "";
        width: 20px;
        height: 20px;
        background: $color-player-2;
        border-radius: 50%;
        position: absolute;
        left: 20px;
        top: calc(50% - 20px);
        transform: translateX(90px);
      }
    }
  }
}
