.btn {
    text-decoration: none;
    color: $white;
    font-family: inherit;  
    font-weight: 700;
    font-size: 1.2em;
    text-transform: uppercase;
  
    text-align: center;  
  
    display: inline-block;
    position: relative;
    padding: .5em 4em 1em .5em;

    cursor: pointer;
    border: 2px solid $color-player-2;
    background-color: $color-player-2;
    transition: all .15s ease;

    &::after{
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0; 
      height: 0; 
      border-left: .5em solid transparent;
      border-right: .5em solid transparent;
      border-top: .5em solid currentColor;
      transform: rotate(-45deg) translate(.2em,0);
    }
    
        
    &:focus,
    &:hover{
      border-color: $text-color;
      background-color: $text-color;
    }
}

